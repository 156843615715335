import ja from './ja'
import atbbJa from './atbb_ja'
import en from './en'
import travelJa from './travel_ja'
import travelEn from './travel_en'

export default {
  ja,
  en,
  atbb_ja: atbbJa,
  travel_ja: travelJa,
  travel_en: travelEn,
}
