declare const Rails

// @ts-check
/// <reference path = "../../../types/window.d.ts" />

import qs from 'qs'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 24 * 60 * 60
})

export default class {
  static async getOrFetchCache(url: string, query: any = {}): Promise<any> {
    return this.get(url, query, true)
  }

  static async get(url: string, query: any = {}, useCache = false): Promise<any> {
    const encodedQuery = qs.stringify(query, { arrayFormat: 'brackets' })
    const combindURL = encodedQuery == '' ? url : `${url}?${encodedQuery}`

    let params = {
      withCredentials: true
    }
    if(useCache) {
      params = Object.assign(params, { adapter: cache.adapter })
    }

    return (await axios.get(combindURL, params)).data
  }

  static async put(url: string, query: any = {}): Promise<any> {
    const config = {
      method: 'put',
      url: url,
      data: query,
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      withCredentials: true
    }

    // @ts-ignore
    return (await axios.request(config)).data
  }

  static async post(url: string, query: any = {}): Promise<any> {
    const config = {
      method: 'post',
      url: url,
      data: query,
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      withCredentials: true
    }

    // @ts-ignore
    return (await axios.request(config)).data
  }

  static async del(url: string, query: any = {}): Promise<any> {
    const config = {
      method: 'delete',
      url: url,
      data: query,
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      withCredentials: true
    }

    // @ts-ignore
    return (await axios.request(config)).data
  }
}
