import VueI18n from 'vue-i18n'

import messages from './messages'

export default locale => {
  return new VueI18n({
    locale: locale,
    fallbackLocale: 'ja',
    silentTranslationWarn: true,
    messages: messages,
  })
}
