export default {
  _model: {
    place: {
      parent: {
        name: '施設名',
      },
      child: {
        name: 'コンテンツ名',
        attributes: '情報',
        column_map: 'リスト画像',
      },
    },
  },
  vr_control: {
    toggle_place_list: {
      open: '一覧を表示',
      close: '一覧を閉じる'
    },
    unsupported_browsers: '未対応ブラウザです。最新のブラウザで閲覧してください。',
    contents_list: {
      count_label: 'コンテンツ',
      count_unit: '件',
      search_specific_attribute: '検索',
      current_place_label: '現在表示中のコンテンツ',
      search: {
        label: '条件で絞り込む',
        button: '検索条件',
      },
      filter: {
        label: 'さらに絞り込む',
        placeholder: 'コンテンツ名など',
      },
      pinned_only_label: '★のみ表示',
      tab: {
        all: '検索結果',
        list: 'リスト',
        map: 'マップ',
      },
      not_exist_place_list: '物件が存在しません',
      load_from_pinned: '「★を読み込む」からリストを読み込んで下さい',
      not_exist_map_data: '地図表示できる物件が存在しません',
      map_control: {
        landmark_search: {
          placeholder: 'ランドマーク検索'
        },
        google_maps: {
          info_window: {
            organization_name: '店舗',
            address: '所在地',
            switch_button: '切り替える'
          },
        },
      },
      list_control: {
        save_list_button: '★を保存する',
        read_list_button: '★を読み込む',
        save_list_title: 'リストを保存',
        save_as_new: '新しく名前をつけて保存',
        personal: '個人',
        organization_group: '店舗',
        list_name_placeholder: 'リスト名',
        save_button: '保存',
        save_as_existing_list: '既存リストに上書き',
        no_select_option: '選択してください',
        read_list_title: '読み込むリストを選んで下さい',
        save_message: '保存しました',
        blank_list_name: 'リスト名を入力して下さい',
        not_select_list: 'リストを選択して下さい',
      },
      list_item_building: {
        display: '表示',
        show_more: 'もっと見る',
        count_unit: '件',
        item_room: {
          show_external: '物件の詳細を表示します',
          organization_group: '店舗：',
          place_name: 'コンテンツ：',
          pin_button_content: {
            enabled: '★',
            disabled: '☆',
          },
          select_place_button: '切替',
          detail_button: '詳細',
          exist_pre_download_settings: '事前ダウンロード設定あり',
        },
      },
      detail_modal: {
        id: 'ID：',
        organization_group: '店舗：',
        parent_name: '建物名：',
        place_name: 'コンテンツ名：',
        address: '所在地：',
      },
    },
    device_select: {
      select_viewer: 'ビューワーを選択して下さい',
      no_connection: '接続しない',
    },
    panorama: {
      loading_video: '読込中',
      playbackable_account_count: '動画再生可能参加者数',
      confirm_playback: '動画読込中の参加者がいます。動画を再生しますか？',
      no_select_message: {
        please_select: '左のコンテンツリストからコンテンツを選択してください。'
      }
    },
    floor_plan: {
      toggle_control: {
        open: '開く',
        close: '閉じる',
      },
      control_menu: {
        close: '閉じる',
        open: '開く',
        move: '移動',
      },
    },
  },
  places: {
    index: {
      title: '登録コンテンツ一覧',
      number_of_place_children: '{number}コンテンツ',
      sub_title: '登録コンテンツの一覧です',
      add_child: 'コンテンツを追加する',
      parent_edit: '編集する',
    },
    tasks: {
      map: 'リスト画像連続登録リスト',
      public: 'コンテンツ公開リスト',
    },
  },
  group_calls: {
    you: 'あなた',
    unread_messages_from_here: 'ここから未読メッセージ',
    shared_medium_kind: {
      site: '外部サイト',
      media: '動画・画像',
      place: '物件・バーチャルツアー',
    },
    shared_medium_value: {
      site: '外部サイトURL',
      media: '動画・画像URL',
      place: '物件ID',
    },
    shared_medium_placeholder: {
      site: 'https://example.com',
      media: 'https://example.com/video.mp4',
      place: '物件ID (数字)',
    },
  },
}
